<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <slot name="title">
          <v-chip
            slot="activator"
            v-on="on"
            :style="titleStyle"
            :class="`${titleClass} title-text`"
          >
            {{ title }}: {{ selectedItemsStr }}
          </v-chip>
        </slot>
      </template>

      <slot name="content">
        <v-card width="600" :style="contentStyle">
          <v-toolbar flat dark>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="menu = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <template v-if="items.length === 0">
            <slot name="emptyMessage">
              <v-alert
                class="ma-3"
                border="left"
                colored-border
                color="red"
                elevation="2"
                icon="mdi-alert"
              >
                {{ emptyMessage }}
              </v-alert>
            </slot>
          </template>
          <v-card-text v-else class="pa-0 d-flex">
            <v-row dense no-gutters>
              <v-col cols="12" lg="6">
                <v-sheet
                  style="
                    border: 1px solid #ddd;
                    max-height: 400px;
                    overflow-y: auto;
                  "
                >
                  <v-text-field
                    v-model="search"
                    class="px-3"
                    dense
                    height="35"
                    full-width
                    append-outer-icon="mdi-magnify"
                    placeholder="검색"
                    hide-details
                    clearable
                  />
                  <v-treeview
                    v-model="selected"
                    :items="tree"
                    :search="search"
                    :filter="treeFilterMethod"
                    :active="selected"
                    :open="open"
                    v-bind="treeviewAttr"
                    @update:active="v => updateSelectItems(v)"
                    @input="checkMax"
                  >
                    <template v-slot:prepend="{ item }">
                      <v-avatar v-if="item.icon && item.icon.url" tile left>
                        <v-img
                          style="border: 1px solid #ddd"
                          :src="item.icon.url"
                          class="rounded-lg"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:label="{ item }">
                      <v-tooltip
                        v-if="showTooltip"
                        bottom
                        color="rgba(0,0,0,1)"
                      >
                        <template v-slot:activator="{ on: tooltipOn }">
                          <div
                            v-on="{ ...tooltipOn }"
                            class="subtitle1 overflow-eclips"
                          >
                            <div v-if="showIdChip(item.id)">
                              <v-chip
                                x-small
                                dense
                                color="secondary"
                                class="pa-2"
                                label
                                outlined
                              >
                                <div class="font-weight-bold">
                                  {{ item[keyString] }}
                                </div>
                              </v-chip>
                            </div>
                            {{ item[textString] }}
                          </div>
                        </template>
                        <div class="subtitle1">
                          {{ item[textString] }}
                        </div>
                      </v-tooltip>
                      <div v-else class="subtitle1 overflow-eclips">
                        <div v-if="showIdChip(item.id)">
                          <v-chip
                            x-small
                            dense
                            color="secondary"
                            class="pa-2"
                            label
                            outlined
                          >
                            <div class="font-weight-bold">
                              {{ item[keyString] }}
                            </div>
                          </v-chip>
                        </div>
                        {{ item[textString] }}
                      </div>
                    </template>
                  </v-treeview>
                </v-sheet>
              </v-col>
              <v-col cols="12" lg="6">
                <v-sheet
                  style="
                    border: 1px solid #ddd;
                    max-height: 400px;
                    overflow-y: auto;
                  "
                >
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title
                        >선택한 항목
                        {{
                          selected.length > 0 ? `${selected.length}개` : '없음'
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="selected.length > 0">
                      <v-btn color="primary" text @click="removeAll" small
                        >모두 지우기</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item-group style="border-top: 1px solid #ddd">
                    <v-list-item
                      dense
                      v-for="(list, index) in selectedItems"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-avatar v-if="list.icon && list.icon.url" tile left>
                            <v-img
                              style="border: 1px solid #ddd"
                              :src="list.icon.url"
                              class="rounded-lg"
                            ></v-img>
                          </v-avatar>
                          {{ list[textString] }}</v-list-item-title
                        >
                        <v-list-item-subtitle v-if="list.subtitle">{{
                          list.subtitle
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="ma-0">
                        <v-btn icon>
                          <v-icon color="grey lighten-1" @click="remove(index)"
                            >mdi-close</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="primary" text @click="submit()" :disabled="!isSubmit">
              적용
            </v-btn>
          </v-card-actions>
        </v-card>
      </slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    // menu 의 타이틀을 지정합니다.
    title: {
      type: String,
      required: true
    },
    keyName: {
      type: String,
      required: true
    },
    // menu 의 전체아이템을 지정합니다.
    items: {
      type: Array,
      required: true
    },
    // menu 의 선택아이템을 지정합니다.
    defaultValue: {
      type: Array,
      default: () => []
    },
    // 아이템의 text 를 어떻게 보여줄지 정합니다.
    detail: {
      type: Boolean,
      default: false
    },
    // 화면이 처음 로드시 메뉴를 보여줄지 말지 정합니다.
    default: {
      type: Boolean,
      default: false
    },
    // 선택 아이템의 최소 개수를 지정합니다.
    min: {
      type: Number,
      default: 0
    },
    // 선택 아이템의 최대 개수를 지정합니다.
    max: {
      type: Number,
      default: Infinity
    },
    // treeview 에 사용될 key
    keyString: {
      type: String,
      required: true
    },
    // treeview 에 사용될 text
    textString: {
      type: String,
      required: true
    },
    // api 호출 여부를 지정합니다.
    api: {
      type: Boolean,
      default: false
    },
    // 아이템이 없을 경우 노출되는 메시지 입니다.
    emptyMessage: {
      type: String,
      default: ''
    },
    // db에 저장된 필터 사용 유무를 확인합니다.
    isSavedFilter: {
      type: Boolean,
      default: false
    },
    contentStyle: {
      require: false,
      type: Object,
      default: null
    },
    titleClass: {
      require: false,
      type: String,
      default: null
    },
    titleStyle: {
      require: false,
      type: Object,
      default: null
    },
    showTooltip: {
      require: false,
      type: Boolean,
      default: false
    },
    showId: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      advertisers: [],
      advertiserIds: [],
      open: ['all'],
      minDay: '2021-01-01',
      dates: [],
      selected: [],
      selectedItems: [],
      treeItem: [],
      originItem: [],
      selectionType: 'leaf',
      menu: false,
      search: null
    };
  },
  watch: {
    menu(value) {
      const selected = JSON.parse(JSON.stringify(this.defaultValue));
      //현재 선택 아이템 갯수에 따라 autocomplete 선택 항목에서 삭제합니다.
      if (value === false && selected.length === 0) {
        this.$emit('remove');
      } else {
        this.selected = selected;
      }
    },
    items(val) {
      this.originItem = val.length > 0 ? [...val] : [];
      this.treeItem = this.originItem;
    }
  },
  computed: {
    tree() {
      const obj = {};
      obj[this.textString] = '전체선택';
      obj[this.keyString] = 'all';
      obj['children'] = [...this.treeItem];

      return this.treeItem.length > 0 ? [obj] : [];
    },
    // 선택한 값 텍스트로 표시
    selectedItemsStr() {
      if (this.defaultValue.length === 0) {
        return '';
      }
      let str = '';
      if (this.detail) {
        // 상세 표시
        str = this.defaultValue
          .map(v => {
            const sltItem = this.items.find(item => item[this.keyString] === v);
            return sltItem ? sltItem[this.textString] : '';
          })
          .join(',');
      } else {
        // 처음 선택값외 나머지 개수 표시
        const sltItem = this.items.find(
          item => item[this.keyString] === this.defaultValue[0]
        );

        str = `${sltItem[this.textString]} ${
          this.defaultValue.length > 1
            ? `외 ${this.defaultValue.length - 1} 개`
            : ''
        }`;
      }

      return str;
    },
    isSubmit() {
      return this.selected.length > 0 ? true : false;
    },
    /**@description:treeview 속성  */
    treeviewAttr() {
      return {
        itemText: this.textString,
        itemKey: this.keyString,
        selectionType: this.selectionType,
        selectable: true,
        rounded: true,
        hoverable: true,
        activatable: true,
        openAll: true,
        multipleActive: true
      };
    }
  },
  created() {
    // 선택된 항목이 존재하면 메뉴를 열지 않습니다.
    if (this.defaultValue.length > 0) {
      this.menu = false;
      if (this.isSavedFilter === false) {
        this.$emit('callApi');
      }
    } else {
      this.menu = true;
    }

    this.originItem = this.items.length > 0 ? [...this.items] : [];
    this.treeItem = this.originItem;
  },
  mounted() {
    this.selected = JSON.parse(JSON.stringify(this.defaultValue));
  },
  methods: {
    submit() {
      let errorMsg = '';

      if (this.selected.length < this.min) {
        errorMsg = `최소 ${this.min} 개 이상 선택이 필요한 항목입니다.`;
      }

      if (!errorMsg && this.selected.length > this.max) {
        errorMsg = `최대 ${this.max} 개 선택 가능한 항목입니다.`;
      }

      if (errorMsg) {
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: errorMsg,
          confirmButtonText: '확인'
        });
        return;
      }
      if (this.selected !== this.defaultValue) {
        this.$emit('changeValue', {
          key: this.keyName,
          value: this.selected
        });
      }

      this.menu = false;
      // api 가 true 인경우 callApi 호출후 callApi 에서 applyFilter를 emit 하도록 한다.
      if (this.api === true) {
        this.$emit('callApi');
      } else {
        this.$emit('applyFilter');
      }
    },
    remove(index) {
      this.selected.splice(index, 1);
    },
    removeAll() {
      this.selected = [];
    },
    // max 값을 초과할 경우 최근선택한 아이템의 max 개 만큼만 선택하도록 합니다.
    checkMax(e) {
      if (e.length > this.max) {
        this.selected = e.slice(Math.max(e.length - this.max, 0));
      }
    },
    updateSelectItems(v) {
      this.selected = v;
      this.selectedItems = this.items.filter(item =>
        this.selected.includes(item[this.keyString])
      );
    },
    showIdChip(val) {
      return this.showId && val !== 'all' && !!val;
    },
    treeFilterMethod(item) {
      const idText = item.id + '';
      return (
        item[this.textString]?.includes(this.search) ||
        idText.includes(this.search)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.overflow-eclips {
  background-color: efefef;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
